import {
  all, takeEvery, fork, call, put, select,
} from 'redux-saga/effects';
import api from 'services';
import actions from 'redux/hostedTables/actions';

export function* getHostedTablesConfig() {
  yield takeEvery(actions.GET_HOSTED_TABLES_CONFIG, function* () {
    yield put({
      type: actions.SET_INIT,
      init: true,
    });

    const response = yield call(api.fetchItem, { entityType: 'custom-tables', needResponse: true });
    if (response.status === 200) {
      const data = response.data;
      yield put({
        type: actions.GET_HOSTED_TABLES_CONFIG_SUCCESS,
        dataTypes: data.dataTypeMapping,
        tableNames: data.tableNames.map((val) => {
          const tableDescriptions = data?.tableDescriptions?.find((item) => item.tableName === val);
          return {
            tableName: val,
            columnDescriptions: tableDescriptions?.columnDescriptions,
          };
        }),
        maxTablesCount: data.maxTablesCount,
        maxColumnsCount: data.maxColumnsCount,
        maxRowsCount: data.maxRowsCount,
        tablePrefix: `${data.tablePrefix}_`,
        reservedColumnNames: data?.reservedColumnNames
      });
    } else {
      yield put({
        type: actions.SET_INIT,
        init: false,
      });
    }
  });
}

export const uploadHostedTableSelector = (state) => state.hostedTables;
export const tableNamesSelector = (state) => state.hostedTables.tableNames;

export const uploadFileAPI = (file, containsHeader, tableName, truncateTableInExasol, delimiter) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('headersExist', containsHeader);
  formData.append('truncateTableInExasol', truncateTableInExasol);
  formData.append('tableName', tableName);
  formData.append('delimiter', delimiter);

  return api.postFile({
    url: 'custom-tables/upload',
    data: formData,
    msg: `"${tableName}" data has been successfully saved`
  });
};

export function* uploadHostedTable() {
  yield takeEvery(actions.UPLOAD_HOSTED_TABLE, function* ({ columns }) {
    yield put({ type: actions.SET_LOADING, loading: true });
    const { tablePrefix, tableName, file, containsHeader, uploadData, delimiter } = yield select(uploadHostedTableSelector);
    const tableNameUpdated = tablePrefix + tableName.toUpperCase();
    const customTableCreate = yield call(api.post, {
      url: 'custom-tables',
      data: { tableName: tableNameUpdated, columns },
      msg: `"${tableNameUpdated}" table has been successfully created.${uploadData ? ' Data insertion is in progress' : ''}`,
    });
    if (customTableCreate?.status === 200) {
      const tableNames = yield select(tableNamesSelector);
      yield put({
        type: actions.SET_HOSTED_TABLE_NAMES,
        tableNames: [
          { 
            tableName: tableNameUpdated,
            columnDescriptions: columns.map((val) => {
              return {
                columnName: val.columnName,
                columnType: val.dataType,
                nullable: val.isNullable,
              };
            }),
          },
          ...tableNames
        ],
      });
      if (uploadData) {
        try {
          yield call(uploadFileAPI, file, containsHeader, tableNameUpdated, false, delimiter);
        } catch (e) {

        }
      }
    }
    yield put({ type: actions.RESET_POPUP });
  });
}

export function* editHostedTable() {
  yield takeEvery(actions.EDIT_HOSTED_TABLE, function* ({ tableName, containsHeader, file, truncateTableInExasol, delimiter }) {
    yield put({ type: actions.SET_LOADING, loading: true });
    try {
      yield call(uploadFileAPI, file, containsHeader, tableName, truncateTableInExasol, delimiter);
    } catch (e) {

    }
    yield put({ type: actions.RESET_POPUP });
  });
}

export function* deleteHostedTable() {
  yield takeEvery(actions.DELETE_HOSTED_TABLE, function* ({ tableName }) {
    const tableNames = yield select(tableNamesSelector);
    yield put({ type: actions.SET_INIT, init: true });
    const deleted = yield call(api.delete, {
      url: `custom-tables/${tableName}`,
      msg: `"${tableName}" has been successfully deleted`
    });

    if (deleted?.status === 200) {
      yield put({
        type: actions.SET_HOSTED_TABLE_NAMES,
        tableNames: tableNames.filter(val => val.tableName !== tableName)
      });
    }
    yield put({ type: actions.SET_INIT, init: false });
  });
}


export default function* rootSaga() {
  yield all([
    fork(getHostedTablesConfig),
    fork(uploadHostedTable),
    fork(deleteHostedTable),
    fork(editHostedTable),
  ]);
}