const jobActions = {
  FETCH_JOBS: 'FETCH_JOBS',
  FETCH_JOBS_SUCCESS: 'FETCH_JOBS_SUCCESS',
  FETCH_JOBS_FAILURE: 'FETCH_JOBS_FAILURE',
  SELECT_JOB: 'SELECT_JOB',
  ADD_JOB: 'ADD_JOB',
  ADD_JOB_SUCCESS: 'ADD_JOB_SUCCESS',
  ADD_JOB_FAILURE: 'ADD_JOB_FAILURE',
  GET_JOB: 'GET_JOB',
  DELETE__JOB: 'DELETE__JOB',
  CHANGE_JOB: 'CHANGE_JOB',
  CHANGE_JOB_VIEW: 'CHANGE_JOB_VIEW',
  CLEAR_JOBS: 'CLEAR_JOBS',
  SORT_JOBS: 'SORT_JOBS',
  CANCEL_JOB: 'CANCEL_JOB',
  CANCEL_JOB_SUCCESS: 'CANCEL_JOB_SUCCESS',
  CANCEL_JOB_FAILURE: 'CANCEL_JOB_FAILURE',
  FETCH_USERS: 'FETCH_USERS',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
  FETCH_JOB_CREATORS: 'FETCH_JOB_CREATORS',
  FETCH_JOB_CREATORS_SUCCESS: 'FETCH_JOB_CREATORS_SUCCESS',
  FETCH_JOB_CREATORS_FAILURE: 'FETCH_JOB_CREATORS_FAILURE',
  SET_SYNC_ONTOLOGIES_LOADING: 'SET_SYNC_ONTOLOGIES_LOADING',

  sortJobs: (e) => ({
    type: jobActions.SORT_JOBS,
    sort: e.sort,
  }),
  clearJobs: () => ({
    type: jobActions.CLEAR_JOBS,
  }),
  fetchJobs: (url) => ({
    type: jobActions.FETCH_JOBS,
    url
  }),
  selectJob: (selectedItem) => ({
    type: jobActions.SELECT_JOB,
    selectedItem,
  }),
  addJob: (data, onFinish) => ({
    type: jobActions.ADD_JOB,
    data,
    onFinish,
  }),
  getJob: (data) => ({
    type: jobActions.GET_JOB,
    data,
  }),
  changeJobView: (selectedItem) => ({
    type: jobActions.CHANGE_JOB_VIEW,
    selectedItem,
  }),
  cancelJob: (id) => ({
    type: jobActions.CANCEL_JOB,
    id,
  }),
  cancelJobSuccess: () => ({
    type: jobActions.CANCEL_JOB_SUCCESS,
  }),
  cancelJobFailure: (err) => ({
    type: jobActions.CANCEL_JOB_FAILURE,
    err,
  }),
  fetchUsers: () => ({
    type: jobActions.FETCH_USERS,
  }),
  fetchJobCreators: (data) => ({
    type: jobActions.FETCH_JOB_CREATORS,
    data
  })

};
export default jobActions;
