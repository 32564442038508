/** @prettier */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import { all, takeEvery, fork, call, put, take, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { set } from 'lodash';
import api from 'services';
import { prepJob, transformJobs } from 'transform/job';

import { showToast } from 'utils/toast';
import categoryActions from '../categories/actions';
import actions from './actions';
import platformApi from "services/platform";
const entityType = 'jobsBatch';

export function* fetchJobsSaga() {
  yield takeEvery(actions.FETCH_JOBS, function* fetchJob({url}) {
    const dataResponse =
    yield call(platformApi.platformGetReq, { urlPath: url ? `jobs/batch?${url}` : 'jobs/batch', disableNotification: true,});
    let data = transformJobs(dataResponse) || null;
    if (data) {
      data = data.map((val) => {
        set(val, 'dateBeginStr', moment(val.dateBegin).utc().format('M/D/YYYY'));
        return val;
      });
    }

    yield put({ type: data ? actions.FETCH_JOBS_SUCCESS : actions.FETCH_JOBS_FAILURE, ...(data && { data, pagination: dataResponse.pagination }) });
  });
}

export function* addJobSaga() {
  yield takeEvery(actions.ADD_JOB, function* addJob({ data, onFinish = () => {} }) {
    const { jobType } = data;
    if (jobType === 'SYNC_ONTOLOGIES') {
      try {
        yield put({ type: actions.SET_SYNC_ONTOLOGIES_LOADING, payload: true });
        yield call(api.fetch, { entityType: 'syncOntologies' });
        // yield put({ type: actions.CHANGE_JOB_VIEW });
        showToast('success', { task: 'Configurations synced' });
      } catch (e) {
        showToast('error', { task: "Couldn't sync configurations" });
      }
      yield put({ type: actions.SET_SYNC_ONTOLOGIES_LOADING, payload: false });
    } else {
      const preppedData = prepJob(data);

      const job = yield call(api.editItem, {
        entityType,
        data: preppedData,
        toastAction: 'Created',
      });
      if (job) {
        if (data.jobType === 'BOTH') {
          yield put({ type: categoryActions.FETCH_CATEGORIES });
        } else {
          yield put({ type: actions.ADD_JOB_SUCCESS, selectedItem: job });
          yield put({ type: actions.FETCH_JOBS });
        }
      } else {
        yield put({ type: actions.ADD_JOB_FAILURE });
      }
    }
    onFinish();
  });
}

export function* cancelJobSaga() {
  yield takeLatest(actions.CANCEL_JOB, function* cancelJob({ id }) {
    const res = yield call(api.cancelJobItem, {
      entityType: 'jobsBatch',
      data: {},
      id,
    });
    if (res?.status === 200) {
      yield put({ type: actions.CANCEL_JOB_SUCCESS });
      yield put({ type: actions.FETCH_JOBS });
    } else {
      yield put({ type: actions.CANCEL_JOB_FAILURE });
    }
  });
}

export function* fetchUsersSaga() {
  yield take(actions.FETCH_USERS, function* () {
    const data = yield call(api.fetchAll, {
      entityType: 'users',
    });
    data ? yield put({ type: actions.FETCH_USERS_SUCCESS, data }) : yield put({ type: actions.FETCH_USERS_FAILURE });
  });
}

export function* fetchJobCreatorsSaga() {
  yield takeEvery(actions.FETCH_JOB_CREATORS, function* ({ data }) {
    const res = yield call(api.fetchByPost, {
      entityType: 'users/info',
      data,
    });
    if (!res) {
      return yield put({ type: actions.FETCH_JOB_CREATORS_FAILURE });
    }
    yield put({ type: actions.FETCH_JOB_CREATORS_SUCCESS, data: res });
  });
}

export default function* rootSaga() {
  yield all([fork(fetchJobsSaga), fork(addJobSaga), fork(cancelJobSaga), fork(fetchJobCreatorsSaga)]);
}
