import actions from './actions';

const initState = {
  init: true,
  step: 1,
  uploadPopupVisible: false,
  loading: false,
  tableName: '',
  file: null,
  containsHeader: true,
  dataTypes: {},
  tableNames: [],
  maxTablesCount: 0,
  maxColumnsCount: 0,
  maxRowsCount: 0,
  tablePrefix: '',
  truncateTableInExasol: true,
  isEditPopup: false,
  numberOfColumn: 1,
  uploadData: true,
  delimiter: ',',
  reservedColumnNames:[]
};

export default function hostedTablesReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_STEP: {
      return {
        ...state,
        step: action.step,
      };
    }
    case actions.SET_HOSTED_TABLES_STEP_1: {
      if (!state.loading) {
        return {
          ...state,
          step: action.step,
          tableName: action.tableName,
          file: action.file,
          containsHeader: action.containsHeader,
          numberOfColumn: action.numberOfColumn,
          uploadData: action.uploadData,
          delimiter: action.delimiter,
        };
      }

      return state;
    }

    case actions.GET_HOSTED_TABLES_CONFIG_SUCCESS:
      return {
        ...state,
        dataTypes: action.dataTypes,
        tableNames: action.tableNames,
        maxTablesCount: action.maxTablesCount,
        maxColumnsCount: action.maxColumnsCount,
        maxRowsCount: action.maxRowsCount,
        init: false,
        tablePrefix: action.tablePrefix,
        truncateTableInExasol: true,
        numberOfColumn: 1,
        uploadData: true,
        reservedColumnNames: action?.reservedColumnNames
      };

    case actions.RESET_POPUP:
      return {
        ...state,
        uploadPopupVisible: false,
        loading: false,
        step: 1,
        tableName: '',
        file: null,
        containsHeader: true,
        truncateTableInExasol: true,
        isEditPopup: false,
        numberOfColumn: 1,
        uploadData: true,
        delimiter: ',',
      };

    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case actions.SET_HOSTED_TABLE_NAMES:
      return {
        ...state,
        tableNames: action.tableNames,
      };

    case actions.SET_INIT:
      return {
        ...state,
        init: action.init,
      };

    case actions.SET_EDIT_FORM: {
      if (!state.loading) {
        return {
          ...state,
          tableName: action.tableName,
          isEditPopup: action.isEditPopup,
          file: null,
          step: 1,
          containsHeader: true,
          truncateTableInExasol: true,
          numberOfColumn: 1,
          uploadData: true,
          delimiter: ',',
        };
      }

      return state;
    }

    case actions.SET_UPLOAD_POPUP_VISIBLE: {
      if (!state.loading) {
        return {
          ...state,
          uploadPopupVisible: action.uploadPopupVisible,
        };
      }

      return state;
    }

    case actions.SET_PREVIEW: {
      return {
        ...state,
        step: 3,
        file: action.file,
        delimiter: action.delimiter,
        containsHeader: action.containsHeader,
        tableName: action.tableName,
      };
    }

    default:
      return state;
  }

}