import _ from 'lodash';

import { searchData } from 'utils';

export const transformAccount = (item) => ({
  ...item,
  updatedDt: 'updatedDate' in item ? new Date(item.updatedDate) : null,
  updatedDate: new Date(item.updatedDate),
  vbId: item.id,
  billingSameAs: item.billingContactFirstName === item.technicalContactFirstName
    && item.billingContactLastName === item.technicalContactLastName
    && item.billingContactEmail === item.technicalContactEmail
    && item.billingContactPhone === item.technicalContactPhone,
});

export const transformPlatformAccount = (account) => {
  const {
    id, name, allowSuperAdminAccess, technicalContacts, billingContacts,
  } = account;
  return {
    id,
    name,
    allowSuperAdminAccess,
    technicalContactEmail: technicalContacts?.[0]?.email,
    technicalContactFirstName: technicalContacts?.[0]?.firstName,
    technicalContactLastName: technicalContacts?.[0]?.lastName,
    technicalContactPhone: technicalContacts?.[0]?.phone,
    technicalContactTitle: technicalContacts?.[0]?.title,
    billingContactEmail: billingContacts?.[0]?.email,
    billingContactFirstName: billingContacts?.[0]?.firstName,
    billingContactLastName: billingContacts?.[0]?.lastName,
    billingContactPhone: billingContacts?.[0]?.phone,
    billingContactTitle: billingContacts?.[0]?.title,
    vbId: id,
    billingSameAs: technicalContacts?.[0]?.firstName === billingContacts?.[0]?.firstName
        && technicalContacts?.[0]?.lastName === billingContacts?.[0]?.lastName
        && technicalContacts?.[0]?.email === billingContacts?.[0]?.email
        && technicalContacts?.[0]?.phone === billingContacts?.[0]?.phone,
  };
};
export const transformAccounts = (data) => data.accounts.map((item) => transformAccount(item));

export const transformEditedAccount = (item) => {
  const account = transformAccount(item.account);
  account.etlConfig = item.etlConfig || {};
  account.etlConfigAzure = item.etlConfigAzure || {};
  return { ...account };
};

const fieldsToRemove = [
  'createdDt',
  'updatedDt',
  // 'pciRedaction',
  'pciRedactionType',
  '_flags',
  'licenses',
  'subscriptions',
  'numUsers',
  'numPeople',
  'numOrgs',
  'entitlementList',
  'vbId',
  'hasAdminPrivileges',
  'hasAccountAccess',
  'adminEnabled',
  'techSameAs',
  'billingSameAs',
  'skipAdminUser',
  'autoDisableVBAccess',
];

// TODO: Had to do this to get EA-1281 out
export const prepEditMyAccount = (data) => {
  const account = prepAccount(data);
  const etlConfig = { ...account.etlConfig };
  const etlConfigAzure = { ...account.etlConfigAzure };
  if (_.isEmpty(etlConfig)) {
    if (_.isEmpty(etlConfigAzure)) {
      return { account, etlConfigAzure: null, etlConfig: null };
    } else {
      delete account.etlConfigAzure;
      etlConfigAzure.type = 'AZURE';
      return {
        account,
        etlConfigAzure,
      };
    }
  }
  delete account.etlConfig;
  etlConfig.type = 'S3';
  etlConfig.s3ExternalId = account.id || data.id;
  if (etlConfig.s3Folder == null || etlConfig?.s3Folder?.trim()?.length === 0) {
    etlConfig.s3Folder = '/';
  }
  return {
    account,
    etlConfig,
  };
};

export const prepAccount = (data) => {
  if (data.techSameAs) {
    data.technicalContactFirstName = data.adminFirstName;
    data.technicalContactLastName = data.adminLastName;
    data.technicalContactEmail = data.adminEmail;
    data.technicalContactPhone = data.adminPhone;
    data.technicalContactTitle = '';
  }

  if (data.billingSameAs) {
    data.billingContactFirstName = data.technicalContactFirstName;
    data.billingContactLastName = data.technicalContactLastName;
    data.billingContactEmail = data.technicalContactEmail;
    data.billingContactPhone = data.technicalContactPhone;
    data.billingContactTitle = data.technicalContactTitle;
  }

  return _.omit({
    ...data,
    id: data.vbId,
    status: 'ACTIVE',
  }, fieldsToRemove);
};

export const getAccountOptions = (accountids = [], currentAccount = {}) => {
  const accounts = [
    ...accountids.filter((x) => x.allowSuperAdminAccess).map((a) => ({
      value: a.id,
      label: a.name,
      defaultOption: a.id === currentAccount.id,
      rootAccount: a.rootAccount,
      data: a,
    })),
  ];
  const voiceBase = accounts.filter((x) => x.rootAccount);
  return [
    ...voiceBase,
    ...(_.orderBy(accounts.filter((x) => !x.rootAccount), (x) => x.label.toLowerCase())),
  ];
};

export const addAccountDefaults = (data = {}) => ({
  ...data,
});
export const filterAccounts = (data, filters) => searchData(data, filters, ['name', 'id', 'siteId', 'contractCode']);

export const prepValidateConnection = (data) => {
  const etlConfig = { ...data.etlConfig };
  const etlConfigAzure = { ...data.etlConfigAzure };
  if (_.isEmpty(etlConfig)) {
    if (_.isEmpty(etlConfigAzure)) {
      return { etlConfigAzure: null, etlConfig: null };
    } else {
      etlConfigAzure.type = 'AZURE';
      return {
        ...etlConfigAzure,
      };
    }
  }
  etlConfig.type = 'S3';
  etlConfig.s3ExternalId = data.id;
  if (etlConfig.s3Folder == null || etlConfig?.s3Folder?.trim()?.length === 0) {
    etlConfig.s3Folder = '/';
  }
  return {
    ...etlConfig,
  };
};
