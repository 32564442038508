/** @prettier */
import React, { useEffect, useRef, useState } from 'react';
import { Field, Form } from 'formik';
import { Row, Tooltip, Tabs, Select } from 'antd';
import Dialog from 'components/UI/Dialog';
import FormTitle from 'components/UI/FormTitle';
import Divider from 'components/UI/Divider';
import { NewAccountFormButtons as FormButtons, Input, Error } from 'components/VBForm';
import { FaClipboard } from 'react-icons/fa';
import { copyToClipboard } from 'utils';
import { InputWithIcon } from 'components-shared/inputs/InputWithIcon';
import Icon from 'components/Icon';
import { Button } from 'components-shared/buttons/Button';
import { useSelector } from 'react-redux';
import { isEmpty, size } from 'lodash';
import Loading from 'components/uielements/Loading';
import { Required, AccountImportInput, CheckboxWrap } from './styles';
import AccountFormInputs, { CONTRACT_CODE_VALUES, engagementWarningMessage } from './AccountFormInputs';

const { TabPane } = Tabs;
const { Option } = Select;
let initStorageType = '';

export const customerHostedDataFields = [
  {
    label: 'Not Configured',
    value: 'None',
  },
  {
    label: 'AWS S3',
    value: 'S3',
  },
  {
    label: 'Azure Blob Storage',
    value: 'Azure',
  },
];

export const AccountTabs = {
  OUTPUT: 'output',
  ACCOUNT: 'account',
};

const AccountForm = ({
  toggleView,
  values,
  handleSubmit,
  focusTab,
  errors,
  isSubmitting,
  editMode,
  canDisableForTesting,
  setFieldValue,
  isLoading,
  changeAccountFocusTab,
  validateConnection,
  embededTableauDashboradData,
  submitingForm
}) => {
  const {
    createdDate,
    updatedDate,
    etlConfig,
    etlConfigAzure = {},
    dataStorage,
    accountId,
    products = [],
    allowSuperAdminAccess,
    isImportAccountFlow,
    fetchPlatformAccount,
    getEmbededDashboardUrl
  } = values;
  const isFetchingAccountFromPlatformFailure = useSelector(
    (state) => state.Accounts.isFetchingAccountFromPlatformFailure
  );
  const isFetchingAccountFromPlatform = useSelector((state) => state.Accounts.isFetchingAccountFromPlatform);
  const modalRef = useRef(null);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [isFormValidated, setIsFormValidated] = useState(false);
  useEffect(() => {
    if (etlConfig?.type === 'S3' || dataStorage === 'S3') {
      setFieldValue('dataStorage', 'S3');
      initStorageType = 'S3';
    } else if (etlConfigAzure?.type === 'AZURE' || dataStorage === 'Azure') {
      setFieldValue('dataStorage', 'Azure');
      initStorageType = 'Azure';
    } else {
      setFieldValue('dataStorage', 'None');
      initStorageType = 'None';
    }
  }, []);

  const customerHostedDataFields = [
    {
      label: 'Not Configured',
      value: 'None',
    },
    {
      label: 'AWS S3',
      value: 'S3',
    },
    {
      label: 'Azure Blob Storage',
      value: 'Azure',
    },
  ];
  const checkErrors = () => {
    if (!isEmpty(errors)) {
      if ((errors.etlConfig || errors.etlConfigAzure) && size(errors) === 1) {
        changeAccountFocusTab(AccountTabs.OUTPUT);
      } else {
        changeAccountFocusTab(AccountTabs.ACCOUNT);
        if (modalRef.current) {
          const errorFields = modalRef.current.getElementsByClassName('form-error');
          if (errorFields.length > 0) {
            // jump to first input that has a validation error
            const firstError = errorFields[0];
            firstError.previousSibling.scrollIntoView();
          }
        }
      }
    }
  };

  const fetchAccountFromPlatform = () => fetchPlatformAccount(accountId);
  const canConfigureETL = products.find((i) => i.name === 'EA') && allowSuperAdminAccess && editMode;

  const handleValidateConnection = () => {
    setIsFormValidated(true);
    validateConnection({
      values,
      onFinish: () => {
        setIsFormValidated(false);
      },
    });
  };
  return (
    <Dialog
      title={
        <FormTitle
          {...(isImportAccountFlow && { customTitle: 'Import Account' })}
          type="Account"
          editMode={editMode}
          created={createdDate}
          updated={updatedDate}
        />
      }
      onClose={toggleView}
      width={800}
      maskClosable={false}
      noMotion
      className="account-dialog"
    >
      {isLoading ? (
        <div style={{ height: '50vh' }}>
          <Loading />
        </div>
      ) : (
        <>
          <Tabs defaultActiveKey={focusTab} activeKey={focusTab} onTabClick={(key) => changeAccountFocusTab(key)}>
            <TabPane
              key="account"
              tab={
                <span>
                  ACCOUNT
                  {errors.etlConfig && size(errors) === 1 ? '' : <Required />}
                </span>
              }
            >
              <Form className="k-form scroll-content-modal" ref={modalRef}>
                {isImportAccountFlow ? (
                  <>
                    <AccountImportInput>
                      <InputWithIcon
                        title="Account ID"
                        field="accountId"
                        disabled={editMode}
                        value={accountId}
                        icon={<Icon name="envelope" />}
                      />
                      <Button
                        style={{ marginTop: '37px', marginLeft: '5px' }}
                        type="primary"
                        className="n-button sg-button sg-primary"
                        onClick={fetchAccountFromPlatform}
                        disabled={errors.accountId || isFetchingAccountFromPlatform || editMode}
                      >
                        Import
                      </Button>
                    </AccountImportInput>
                    {isFetchingAccountFromPlatformFailure && <div>No accounts match this ID</div>}
                    {editMode && (
                      <AccountFormInputs
                        setFieldValue={setFieldValue}
                        values={values}
                        editMode={false}
                        errors={errors}
                        embededTableauDashboradData={embededTableauDashboradData}
                      />
                    )}
                  </>
                ) : (
                  <AccountFormInputs
                    setFieldValue={setFieldValue}
                    values={values}
                    canDisableForTesting={canDisableForTesting}
                    editMode={editMode}
                    errors={errors}
                    embededTableauDashboradData={embededTableauDashboradData}
                  />
                )}
              </Form>
            </TabPane>
            {canConfigureETL && (
              <TabPane
                tab={
                  <span>
                    DATA OUTPUT
                    {(dataStorage === 'S3' || dataStorage === 'Azure') && <Required />}
                  </span>
                }
                key="output"
              >
                <Form className="k-form scroll-content">
                  <p className="input_label" style={{ margin: '0px 0px' }}>
                    STORAGE TYPE
                  </p>
                  <Select
                    onChange={(e) => {
                      setFieldValue('dataStorage', e);
                    }}
                    value={dataStorage}
                    dropdownClassName="custom_ant_select account_type_select custom_dropdown dark-select"
                    placeholder="Select storage type"
                    style={{
                      width: '100%',
                      height: '36px',
                      margin: '0px 5px 10px 5px',
                    }}
                  >
                    {customerHostedDataFields.map((el) => {
                      return (
                        <Option key={`${el.label}`} value={el.value}>
                          <span>{el.label}</span>
                        </Option>
                      );
                    })}
                  </Select>
                  {dataStorage === 'S3' && (
                    <>
                      <Row>
                        <label>External ID</label>
                        <Input
                          field={{
                            disabled: true,
                            addonAfter: (
                              <Tooltip title="Copy to clipboard">
                                <div
                                  type="button"
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => copyToClipboard(values.id)}
                                >
                                  <FaClipboard />
                                </div>
                              </Tooltip>
                            ),
                            defaultValue: values.id,
                          }}
                        />
                        <p style={{ marginBottom: '10px' }}>Required to be set as external id in S3 Bucket setup</p>
                      </Row>
                      <Row>
                        <InputWithIcon
                          title="S3 Role"
                          field="etlConfig.s3Role"
                          value={etlConfig?.s3Role || ''}
                          required
                        />
                      </Row>
                      <Row>
                        <InputWithIcon
                          title="Bucket"
                          field="etlConfig.s3Bucket"
                          value={etlConfig?.s3Bucket || ''}
                          required
                        />
                      </Row>
                      <Row>
                        <InputWithIcon title="Folder" field="etlConfig.s3Folder" value={etlConfig?.s3Folder || ''} />
                      </Row>
                    </>
                  )}
                  {dataStorage === 'Azure' && (
                    <>
                      <Row>
                        <InputWithIcon
                          title="Azure Blob Service URL"
                          field="etlConfigAzure.azureBlobServiceURL"
                          value={etlConfigAzure?.azureBlobServiceURL || ''}
                          required
                        />
                      </Row>
                      <Row>
                        <InputWithIcon
                          title="Azure Container Name"
                          field="etlConfigAzure.azureContainerName"
                          value={etlConfigAzure?.azureContainerName || ''}
                          required
                        />
                      </Row>
                      <Row>
                        <InputWithIcon
                          title="Azure SAS Token"
                          field="etlConfigAzure.azureSASToken"
                          value={etlConfigAzure?.azureSASToken || ''}
                          required
                        />
                      </Row>
                    </>
                  )}
                </Form>
                {(dataStorage === 'S3' || dataStorage === 'Azure') && !isEmpty(errors) && (
                  <div className="form-error">
                    <Required />
                    Please fill required fields with valid input
                  </div>
                )}
              </TabPane>
            )}
          </Tabs>
          {values.contractCode && focusTab !== AccountTabs.OUTPUT && (
            <div style={{ marginTop: '8px' }}>
              <Error className="form-error">
                {[CONTRACT_CODE_VALUES.STANDARD_PRICING, CONTRACT_CODE_VALUES.RESELLER_PRICING].includes(
                  values.contractCode
                )
                  ? engagementWarningMessage.billable
                  : engagementWarningMessage.nonBillable}
              </Error>

              <CheckboxWrap className="checkbox-wrapper" htmlFor="billingAcknowledgement">
                <Field
                  name="billingAcknowledgement"
                  render={({ field, form }) => (
                    <input
                      className="k-checkbox2 pointer"
                      style={{
                        float: 'left',
                        marginTop: '3px',
                      }}
                      type="checkbox"
                      checked={field.value}
                      {...field}
                    />
                  )}
                />
                <span>
                  I Acknowledge <Required />
                </span>
              </CheckboxWrap>
            </div>
          )}
          {((isImportAccountFlow && editMode) || !isImportAccountFlow) && (
            <>
              <Divider />
              <FormButtons
                disabled={submitingForm || !isEmpty(errors) || isFormValidated}
                popUpRequired={initStorageType !== 'None' && initStorageType !== dataStorage}
                showPopupConfirm={showConfirmPopup}
                dataStorageType={dataStorage}
                showValidate={focusTab === AccountTabs.OUTPUT && (dataStorage === 'S3' || dataStorage === 'Azure')}
                validateConnection={handleValidateConnection}
                isFormValidated={isFormValidated}
                setIsFormValidated={setIsFormValidated}
                confirmMessage="Continuing will delete your initial configuration, press OK to continue"
                setShowConfirmPopup={setShowConfirmPopup}
                handleSubmit={() => {
                  checkErrors();
                  handleSubmit();
                }}
                onCancel={toggleView}
                isSubmitting={isSubmitting}
                saveText={isSubmitting ? 'Saving...' : 'Save'}
              />
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default AccountForm;
