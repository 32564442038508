import { getBaseReducer } from 'utils';
import { jobModel as model } from 'models/job';
import actions from './actions';

const initState = {
  ...(getBaseReducer(model)),
  entityType: 'jobs',
  jobCreators: null,
  syncOntologyLoading: false,
  pagination: {}
};

export default function jobReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_JOBS:
      return {
        ...state,
        data: null,
        selectedItem: {},
        init: false,
      };
    case actions.SORT_JOBS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_JOBS:
      return {
        init: false
      }
    case actions.FETCH_JOBS_SUCCESS:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination,
        init: true,
      };
    case actions.SELECT_JOB:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_JOB_SUCCESS:
      return {
        ...state,
        data: [
          action.selectedItem,
          ...state.data,
        ],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE__JOB:
      return {
        ...state,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_JOB_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
    case actions.cancelJob:
      return {
        ...state,
      };
    case actions.cancelJobSuccess:
      return {
        ...state,
      };
    case actions.cancelJobFailure:
      return {
        ...state,
        err: action.err,
      };
    case actions.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.data,
        init: true,
      };
    case actions.FETCH_JOB_CREATORS_SUCCESS:
      return {
        ...state,
        jobCreators: action.data
      };
    case actions.FETCH_JOB_CREATORS_FAILURE:
      return {
        ...state,
        jobCreators: null
      }
    case actions.SET_SYNC_ONTOLOGIES_LOADING:
      return {
        ...state,
        syncOntologyLoading: action.payload
      }
    default:
      return state;
  }
}
